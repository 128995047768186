const ROUTES = {
  ROOT: "/",
  REGISTER: "/register",
  LOGIN: "/login",
  PRODUCTS: "/products",
  SHOPPING_CART: "/shopping_cart",
  ABOUT: "/about",
  CHECKOUT: "/checkout",
  ORDER_CONFIRMATION: "/order_confirmation",
  PURCHASE_HISTORY: "/purchase_history",
  PURCHASE_HISTORY_DETAILS: "/purchase_history_details",
  ADD_PRODUCT: "/add_product",
  EDIT_PRODUCT: "/edit_product",
  EDIT_USER: "/edit_user",
  FORGOT_PASSWORD: "/forgot_password",
  CREATE_NEW_PASSWORD: "/create_new_password",
  GENERAL_MESSAGE: "/general_message",
  USERS_CRM: "/users_CRM",
  ADD_USER: "/add_user",
  TERMS_OF_SERVICE: "/terms_of_service",
  PRIVACY_POLICY: "/privacy_policy",
  ACCESSIBILITY_STATEMENT: "/accessibility_statement",
  INVENTORY_MANAGEMENT: "/inventory_management",
  CONTACT_INFORMATION: "/contact_information",
};

export default ROUTES;
