import React from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import { Typography, Link, Grid } from "@mui/material";
import BackgroundImageLayout from "../layout/BackgroundImageLayout";

const TermsOfServicePage = () => {
  return (
    <>
      <BackgroundImageLayout backgroundImage="/assets/images/terms_of_service.png">
        <Typography
          style={{
            fontSize: "20px",
            textDecoration: "underline",
            textAlign: "center",
          }}
        >
          תנאי שימוש
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          השימוש באתר מרקט גבעת בית הכרם (להלן: "האתר") המנוהל ע״י שי בן יוסף
          (להלן: "הקמעונאי") כפוף לתנאי השימוש המפורטים מטה (להלן: "תנאי
          השימוש") ולמדיניות הפרטיות המפורסמת ב:
          <Link
            href="mailto:shaiby34@gmail.com"
            style={{ color: "blue", textDecoration: "underline" }}
          >
            <span> shaiby34@gmail.com </span>
          </Link>
          (להלן: "מדיניות הפרטיות"). תנאי השימוש מנוסחים בלשון זכר אך מיועדים
          לכל אדם הגולש באתר, נשים וגברים כאחד ("אתה", "הלקוח", "המשתמש/ים" או
          "הגולש/ים"). גלישה ו/או שימוש על ידך באתר, מכל סוג, לרבות הסתמכות על
          המחירים המוצעים באתר ורכישת מוצרים ו/או שירותים, מהווה את הסכמתך
          ואישורך לאמור בתנאי השימוש והתחייבותך על פיהם. הקמעונאי שומר לעצמו את
          הזכות לעדכן את תנאי השימוש מעת לעת, לפי שיקול דעתו הבלעדי וללא צורך
          בהודעה מראש. תוקפו של שינוי תנאי השימוש יחל מרגע פרסומו באתר. זוהי
          חובתך להתעדכן בתנאי השימוש ומדיניות הפרטיות בכל כניסה לאתר. אם אינך
          מסכים לתנאי השימוש של האתר, כולם או חלקם, אינך רשאי לעשות שימוש באתר
          לכל מטרה שהיא ולא תהיה לך כל טענה, תביעה ו/או דרישה כלפי הקמעונאי. אם
          הנך מתחת לגיל 18, אנא קרא תנאים אלה בעיון ובקפידה יחד עם הוריך או
          אפוטרופוס אחר. אם אתה ו/או הוריך לא מסכימים לתנאי השימוש של האתר, כולם
          או חלקם, אינך רשאי לעשות שימוש באתר זה לכל מטרה שהיא.
        </Typography>

        <Typography
          variant="body1"
          sx={{ fontSize: "17px", textAlign: "justify" }}
        >
          <span style={{ fontSize: "17px", textDecoration: "underline" }}>
            1. שירותי האתר
          </span>
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.1</span> האתר הינו מרכז הקניות
          המקוון של הקמעונאי עבור מוצרי מזון ומצרכים אחרים המוצעים למכירה, אשר
          בכפוף לתנאי השימוש, תוכל לערוך בו חיפוש, רכישה והזמנת משלוחים לאזורים
          בהם ניתן שירות משלוחים על ידי הקמעונאי, או לאסוף את הזמנתך באופן עצמאי
          ממיקום האיסוף הייעודי (להלן: "השירותים").
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.2</span>שירות ההזמנה המקוון באתר
          מוגבל לאזורים מסוימים ברחבי הארץ (להלן: "אזורי החלוקה המוגדרים"). לכן,
          באמצעות בדיקה באתר, או בעת הליך ההרשמה, יש לוודא כי אזור המשלוח הרצוי
          נכלל תחת רשימת אזורי החלוקה המוגדרים. הקמעונאי אינו יכול לספק את
          השירות מחוץ לאזורי החלוקה המוגדרים, ולכן לא תתקבל כל הזמנה אשר המען
          המפורט בה מצוי מחוץ לאזורים אלו. גם אם הזמנה כזו נקלטה במערכת,
          הקמעונאי לא יהיה מחויב לספקה.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.3</span> האתר נועד לשימוש פרטי
          בלבד וכל שימוש מסחרי בו ללא קבלת הסכמתה מראש ובכתב של הקמעונאי הינו
          אסור. כמו כן, אינך רשאי להשתמש בתכנים שמקורם באתר ו/או בצד שלישי.
          הקמעונאי יהיה רשאי להפסיק את שימושך באתר בכל עת אם לא תעמוד באחד או
          יותר מתנאי השימוש ו/או מכל סיבה שהיא, ללא הודעה מוקדמת ולפי שיקול דעתו
          הבלעדי.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.4</span> השימוש בשירותים המוצעים
          על ידי האתר והגישה אליהם בטלים ככל ששימוש או גישה זו אסורה לפי החוק.
          השימוש בשירותים ייחשב כהצהרתך והתחייבותך כי (א) כל מידע לרישום שהוגש
          הוא אמיתי ומדויק; (ב) תשמור על עדכניות ואמיתות מידע זה; (ג) אתה בן 18
          שנים ומעלה, או לחילופין, קיבלת אישור מהורה ו/או אפוטרופוס להשתמש
          בשירותים של האתר ו-(ד) השימוש שלך בשירותים אינו מפר כל חוק או תקנה או
          כל התחייבות אחרת.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.5</span> השירותים ו/או צורת האתר
          עשויים להשתנות מעת לעת, ללא הודעה מוקדמת.
        </Typography>

        <Typography
          variant="body1"
          style={{ fontSize: "17px", textDecoration: "underline" }}
        >
          1. הרשמה לאתר
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.1</span> כדי להשתמש בשירותי האתר
          תתבקש לספק לקמעונאי מספר פרטים אודותיך בטופס רישום, לרבות טלפון ופרטי
          כרטיס אשראי. חלק מהנתונים שתתבקש למסור הינם פרטי חובה ויסומנו באופן
          מפורש כהיותם פרטי חובה. לא ניתן להירשם לאתר ו/או לבצע הזמנה ו/או משלוח
          מבלי למסור פרטים אלו.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.2</span> על מנת להשתמש בשירותי
          האתר תתבקש למסור את פרטי החובה על גבי טופס הרישום ולבחור סיסמה, אשר
          תשמש בזיהויך לצורך קבלת השירותים. הנך מתחייב: (א) כי ידוע לך שאתה
          אחראי באופן מלא לשמירת סודיות הסיסמה; (ב) להודיע לקמעונאי באופן מיידי
          על כל שימוש שאינו מורשה בסיסמה שלך ו/או על כל הפרת סודיות אחרת.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.3</span> לא יהיה לקמעונאי כל
          אחריות לשימוש בלתי מורשה של כל צד (לרבות צד ג') בנתונים שנמסרו על ידי
          משתמש.
        </Typography>

        <Typography
          variant="body1"
          style={{ fontSize: "17px", textDecoration: "underline" }}
        >
          1. המוצרים הנמכרים לאתר
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.1</span> באתר נמכרים מוצרי מזון
          ומוצרי צריכה אחרים, כמפורט ברשימות המוצרים המופיעות באתר.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.2</span> הקמעונאי רשאי לשנות בכל
          עת, לפי שיקול דעתו הבלעדי, את ההיצע ו/או מגוון המוצרים המוצגים ברשימת
          המוצרים שבאתר. יובהר, כי תמונות המוצרים המופיעות באתר הינן להמחשה בלבד
          ואינן מחייבות את הקמעונאי.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.3</span> יובהר, כי הרכישה באתר
          היא ללקוחות פרטיים בלבד, ולא תתאפשר מכירה סיטונאית של המוצרים.
          הקמעונאי שומר לעצמו את הזכות למנוע ביצוע רכישות והזמנות המהוות רכישה
          סיטונאית.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}> 1.4 </span>רכישת משקאות
          אלכוהוליים, סיגריות ומוצרי טבק מותרת אך ורק ללקוחות אשר גילם מעל 18
          שנים. בעת הזמנת מוצר כזה אתם מאשרים כי הנכם בוגרים ורשאים לרכוש מוצרים
          מסוג זה על פי חוק.
        </Typography>

        <Typography
          variant="body1"
          style={{ fontSize: "17px", textDecoration: "underline" }}
        >
          1. מחירי המוצרים
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.1</span> הקמעונאי מציג באתר את
          המחירים של המוצרים השונים ואת מחיר דמי המשלוח לפי שיקול דעתו הבלעדי
          (להלן: "מחירי המוצרים"). יובהר, כי מחירי המוצרים המופיעים באתר כוללים
          מס ערך מוסף.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.2</span> הקמעונאי אינו מתחייב כי
          מחירי המוצרים באתר יהיו זהים למחירי המוצרים הנמכרים בסניפי הקמעונאי
          השונים, ובכל מקרה המחיר הקובע למוצרים המסופקים באמצעות השירות הינו
          המחיר המופיע באישור ההזמנה ולא המחיר המופיע על המוצר במדבקת המחיר, ככל
          שתהיה.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.3</span> הקמעונאי רשאי לעדכן את
          מחירי המוצרים המופיעים באתר מעת לעת ללא קשר למועד ביקור המשתמש באתר
          ו/או ביצוע ההזמנה. המחיר הקובע יהיה המחיר במועד עריכת ההזמנה.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.4</span> מוצרים המוצגים למכירה
          במחירי מבצע, יסופקו במחיר המבצע בכפוף לכך שהמבצע הינו בתוקף במועד
          אישור ההזמנה ו/או במועד המשלוח על ידי המשתמש.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.5</span>חיוב בגין מוצרים שמחירם
          תלוי במשקל, למשל ירקות ופירות, יהיה בהתאם לשקילתם בפועל על ידי עובדי
          הקמעונאי. תשומת ליבך, כי במוצרים הנמכרים ומסופקים על פי משקל תתכן
          סטייה סבירה בין המשקל שהוזמן לבין המשקל שיסופק בפועל, כך שהמחיר יחושב
          בהתאם למשקל המוצר שסופק בפועל למשתמש ולא תהיה לך כל טענה בשל כך.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.6</span> הסכום הסופי בו יחויב
          הלקוח יחושב על פי משקל המוצרים שנשלחו בפועל במועד המשלוח. החשבון שיוצג
          בעת השלמת ההזמנה באתר הינו חשבון משוער בלבד ויתכן הבדל בין סכום זה
          לבין סכום החשבון הסופי בפועל. הדבר נובע, בין היתר, מעדכון מחירים,
          תוספות, זיכויים בגין מבצעים באתר, אספקת מוצר חלופי, משקל של המוצרים
          בפועל ועוד.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.7</span> הלקוח יקבל העתק של הקבלה
          הסופית בזמן ביצוע ההזמנה ו/או ביום אספקת המוצרים.
        </Typography>

        <Typography
          variant="body1"
          style={{ fontSize: "11", textDecoration: "underline" }}
        >
          1. ביצוע ההזמנה והתשלום
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.1</span> התשלום באתר יתבצע
          באמצעות כרטיס אשראי תקף בלבד אשר הונפק על ידי אחת מחברות האשראי
          הפועלות בישראל, בכפוף לכך שהן מתירות ביצוע תשלומים ברשת האינטרנט בהתאם
          לסוג הכרטיס האשראי שבידכם וכן בעלי חשבון PayPal. כמו כן, ניתן לבצע
          תשלום בשיחת טלפון עם שירות הלקוחות במספר 02-5354061 או לחילופין על ידי
          יצירת חשבון בחנות, לפי שיקול דעתו של הקמעונאי.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.2</span> הקמעונאי אינו אחראי ולא
          יהיה אחראי לכל טענה בדבר אי נכונות ו/או אי דיוק ו/או פגמים בפרטים
          שנמסרו על ידיך בעת ביצוע הזמנת השירות ו/או לגבי פרטים שלא נקלטו במערכת
          המחשב של הקמעונאי ולכל נזק, חיסרון כיס, וכל טענה אחרת בקשר עם ביצוע
          משלוח ו/או הזמנה.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.3</span> הקמעונאי שומר לעצמו את
          הזכות לקבוע הסדרי תשלומים אחרים ו/או אמצעי תשלום נוספים ככל שיבחר לפי
          שיקול דעתו הבלעדי, לרבות הסדרים לתשלום באמצעים שונים מכרטיסי אשראי.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.4</span> תנאי האשראי יהיו בהתאם
          לתנאים הנהוגים אצל הקמעונאי בעת מועד ההזמנה. מועדי התשלום ומספר
          התשלומים האפשרי יוצגו לאישורך בעת אישור ההזמנה.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.5</span> הקמעונאי יהיה רשאי
          להגביל את כמות כל סוגי המוצרים ו/או מספר יחידות ו/או מספר מוצרים
          מקסימאלי עבור כל הזמנה.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.6</span> הקמעונאי אינו חייב לספק
          הזמנות שסכומן הכולל, בעת השלמת ההזמנה, אינו עולה על סכום מינימאלי של
          150 ₪ וכפי שיקבע מעת לעת. סכום זה עשוי להשתנות בהתאם לאזורי המשלוח
          וזמני אספקתו.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.7</span> לאחר ביצוע ההזמנה, יוכל
          הלקוח לעקוב אחרי מצב ההזמנה שלו דרך חשבונו באתר.
        </Typography>

        <Typography
          variant="body1"
          style={{ fontSize: "11", textDecoration: "underline" }}
        >
          1. דמי משלוח
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.1</span>ההזמנה באתר תחויב בתשלום
          דמי משלוח בסכום שיצוין באתר, הנקבע בהתאם לאזור המשלוח וטווח הזמנים
          המבוקשים בהזמנה. יובהר כי דמי המשלוח הינם תשלום נוסף על תשלום ההזמנה
          ואינם תשר או תשלום אישי לשליח ו/או לכל גורם אחר המעורב בתהליך השילוח,
          ולא יועבר באופן אישי לאף אחד מהם.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.2</span> סכום דמי המשלוח יצורף
          לחשבון הסופי ויוצג בפניך בעת האישור הסופי של פרטי ההזמנה.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.3</span> הקמעונאי שומר לעצמו את
          הזכות לעדכן את דמי המשלוח מעת לעת על פי שיקול דעתו הבלעדי ואינו מתחייב
          להודיע מראש על שינוי ו/או עדכון כאמור.
        </Typography>

        <Typography
          variant="body1"
          style={{ fontSize: "17px", textDecoration: "underline" }}
        >
          1. אספקת המוצרים
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.1</span> המוצרים והמצרכים הנכללים
          בהזמנה יסופקו לכם באמצעות שליח (להלן: ה"משלוח").
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.2</span> כמו כן, יש אפשרות,
          ולפעמים דרישה, לאיסוף עצמי של הזמנה. באיסוף עצמי, על הלקוח לאסוף את
          הזמנתו באופן עצמאי במועד הנבחר, מהסניף הקרוב לביתו, בתיאום עם
          הקמעונאי.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.3</span> המוצרים הנכללים בהזמנה
          יסופקו למען שיימסר על ידך במועד ההרשמה או למען שיפורט בטופס פרטי
          המשלוח. כמו כן, תתבקש לבחור את התאריך וטווח השעות המועדף לקבלת המשלוח
          בהתאם לאפשרויות האספקה המוצעות באתר. מועדי המשלוח המוצעים באתר כפופים
          ליכולת האספקה של הקמעונאי ולשיקול דעתו הבלעדי. במועד שבחרת, עליך להיות
          נוכח בכתובת על מנת ששליחי הקמעונאי יוכלו למסור לך את המשלוח.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.4</span> הקמעונאי יעשה כל
          שביכולתו על מנת לספק את כל המוצרים בהזמנה ובמועד, אולם לא תמיד כל
          המוצרים יהיו זמינים ועל כן, הקמעונאי אינו מתחייב לספק את כל המוצרים
          בהזמנה בין אם צוין כי הם במלאי ובין אם לאו ולא תהא לך כל טענה ו/או
          תביעה כל שהיא כלפי הקמעונאי בגין אי אספקת מוצר כלשהו מההזמנה. יובהר כי
          מוצר שלא יסופק על ידי הקמעונאי לא יחויב בתשלום.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.5</span> במידה שהקמעונאי לא יוכל
          לספק את ההזמנה כפי שהוזמנה על ידי הלקוח, מוקד שירות הלקוחות יעשה
          ניסיון לפנות אל הלקוח על מנת להודיע ללקוח על כך ולקבל את הסכמתו של
          הלקוח לאספקת המוצר החלופי. ככל שמוצר מסוים אינו זמין במלאי, אך קיים
          מוצר חלופי, הקמעונאי ינקוט במדיניות החלפה בהתאם לבחירת הלקוח. לפיכך,
          בעת אישור ההזמנה, על הלקוח לסמן את מדיניות ההחלפה הרצויה לו, כגון,
          החלפה לאחר התייעצות (טלפונית) עם הלקוח, החלפה ללא התייעצות עם הלקוח או
          איסור על החלפה, כאשר הקמעונאי יפעל בהתאם לבחירת הלקוח.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.6</span> הלקוח מתחייב כי הוא או
          מי מטעמו יקבל את ההזמנה בעת אספקת המוצרים. לחילופין, יוכל הלקוח, בזמן
          ביצוע ההזמנה, לאשר את השארת ההזמנה ליד דלת ביתו או בכל מקום אחר הסמוך
          למענו. בזמן אספקת ההזמנה או איסוף עצמי על ידי הלקוח, הקמעונאי או מבצע
          המשלוח מטעמו רשאים לבקש מהלקוח או מהנציג המורשה להציג תעודת זהות ו/או
          את כרטיס האשראי ששימש לביצוע ההזמנה הרלבנטית.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.7</span> יובהר, כי במידה ובעת
          מועד אספקת המוצרים, לא יהיה הלקוח ו/או מי מטעמו במען אליו הזמין את
          משלוח המוצרים, והלקוח לא אישר בעת ביצוע ההזמנה כי הינו מאשר השארת
          ההזמנה ליד דלת ביתו או בכל מקום אחר הסמוך למענו, תוחזר ההזמנה לחנות
          ועל הלקוח תהא האחריות לתאם עם שירות הלקוחות את מועד האספקה בשנית. אי
          קביעת מועד הזמנה חדש על ידי הלקוח, לא תפטור אותו מתשלום ההזמנה שבוצע
          על ידו.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.8</span> במשלוח המוצרים בשנית
          יחויב הלקוח בנוסף לתשלום דמי המשלוח הראשוניים גם בתשלום דמי משלוח
          נוספים. יובהר, כי ככל שאושר על ידי הלקוח השארת ההזמנה בסמוך לדלת מענו
          או במקום אחר הסמוך למענו, מסכים ומאשר הלקוח, כי האחריות בדבר המוצרים
          הינה שלו בלבד ובכל במקרה של גניבה ו/או קלקול ו/או כל טענה אחרת בקשר
          לכך לא יוכל לבוא הלקוח בטענות ו/או תביעות כנגד הקמעונאי .
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.9</span> אי עמידת הקמעונאי במועד
          האספקה המבוקש מכל סיבה שהיא לא תפטור, בכל דרך שהיא, את הלקוח מחובת
          התשלום בגין האספקה.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.10</span> במידה שהמוצרים יארזו
          בשקיות ניילון, ישלם הלקוח את המחיר עבור כל שקית, בהתאם להוראות החוק.
        </Typography>

        <Typography
          variant="body1"
          style={{ fontSize: "17px", textDecoration: "underline" }}
        >
          1. ביטול ושינוי הזמנה
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.1</span> הקמעונאי יעשה כל מאמץ
          לאפשר שינוי בהזמנה על ידי הלקוח. כל בקשה לשינוי יש להפנות למוקד שירות
          הלקוחות של האתר בטלפון 02-5354061 או בדואר אלקטרוני בכתובת
          <Link
            href="mailto:shaiby34@gmail.com"
            style={{ color: "blue", textDecoration: "underline" }}
          >
            <span> shaiby34@gmail.com </span>
          </Link>
          בעת הפניה תתבקש להזדהות באמצעות שם משתמש והסיסמא שבחרת במהלך הרישום
          לאתר.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.2</span> בהתאם לסטטוס ההזמנה של
          הלקוח, יהיה הלקוח רשאי לשנות ו/או לבטל את ההזמנה רק עד המועד בו החל
          הקמעונאי לאסוף את המוצרים הכלולים בהזמנתך. יובהר, כי במקרה כאמור לעיל,
          בו ניתן לבטל את המשלוח הסכום ששולם בגינה יוחזר במלואו ללקוח, בהתאם
          ובכפוף להוראות חוק.
        </Typography>

        <Typography
          variant="body1"
          style={{ fontSize: "17px", textDecoration: "underline" }}
        >
          1. החזרת מוצרים
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.1</span> הלקוח מתבקש לבדוק את
          תכולת המשלוח מיד לאחר קבלתו ולהשוות אותו הן להזמנה והן לחשבונית. בכל
          שאלה או בירור אודות ההזמנה שסופקה תוכל לפנות למוקד שירות הלקוחות של
          האתר בטלפון 02-5354061 או בדואר אלקטרוני בכתובת מייל
          <Link
            href="mailto:shaiby34@gmail.com"
            style={{ color: "blue", textDecoration: "underline" }}
          >
            <span> shaiby34@gmail.com </span>
          </Link>
          תוך 24 שעות ממועד האספקה.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.2</span> לאחר אספקת המשלוח, ניתן
          להחזיר את המוצרים שנרכשו לסניף הקמעונאי, בתנאי שהם סגורים באריזתם
          המקורית ולא נעשה בהם כל שימוש. את ההחזרה יש לתאם עם מוקד שירות הלקוחות
          של האתר, לפי פרקי הזמן המפורטים להלן:
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.2.1</span> מוצרי חלב או פירות
          וירקות - בתוך 48 שעות ממועד קבלת המשלוח.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.2.2</span> מוצרי מזון אחרים -
          בתוך 7 ימים ממועד קבלת המשלוח או עד 10 ימים לפני מועד תפוגת המוצר,
          כמוקדם מבין השניים.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.2.3</span> מוצרים שאינם מוצרי
          מזון או טובין כהגדרתם בחוק הגנת הצרכן, התשמ"א-1981 - עד שבועיים מיום
          קבלת המשלוח.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.3</span> לאחר החזרת המוצרים תזוכה
          במחיר ששילמת עבורם, לא כולל דמי המשלוח. ככל שהזיכוי הכספי יתבצע
          באמצעות מוקד שירות הלקוחות, יתכן ותתבקש לספק את קוד האבטחה של כרטיס
          האשראי (CVV) באמצעותו ביצעת את ההזמנה. הקמעונאי יהיה רשאי לגבות ממך
          דמי ביטול בשיעור של 5% ממחיר העסקה, או 100 ₪ לפי הנמוך מבין השניים,
          ובלבד שההחזרה לא נבעה מפגם או קלקול במוצר. במקרה של פגם או קלקול
          במוצר, לא תחויב בדמי ביטול.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.4</span> הקמעונאי רשאי לשנות ו/או
          לבטל הזמנה ובלבד שימסור על כך ללקוח הודעה לפני מועד האספקה. במקרה של
          ביטול לא יחויב הלקוח בתשלום סך ההזמנה, ובמקרה של שינוי יחויב הלקוח
          בהתאם להזמנה ששונתה.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.5</span> יודגש כי החזרת מוצרים
          וביטול הרכישה תהיינה בהתאם ובכפוף להוראות החוק, לרבות הוראות חוק הגנת
          הצרכן, התשמ"א - 1981, הוראות חוק המכר, התשכ"ח - 1968, והוראות חוק
          כרטיסי חיוב התשמ"ו - 1986, ו/או בכפוף למדיניות הקמעונאי, כפי שיקבע מעת
          לעת.
        </Typography>

        <Typography
          variant="body1"
          style={{ fontSize: "17px", textDecoration: "underline" }}
        >
          1. קניין רוחני
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          כל הנתונים והמידע המצויים באתר, למעט מידע המצוי בקישורים לאתרים האחרים
          שהאתר מפנה ו/או יכול להפנות אליהם, הם רכושו הבלעדי של הקמעונאי ואין
          לראות במידע ובשירותים המסופקים על ידו באתר כמקנים רישיון כלשהו או
          זכויות כלשהן בקניינו הרוחני של הקמעונאי או של צדדים שלישיים, לפי
          העניין. זכויות היוצרים ברעיון העומד ביסוד האתר, לרבות סימניו המסחריים,
          עיצוב האתר, וכן בכל פעולה אחרת באתר הייחודית והבלעדית לקמעונאי, שמורים
          לקמעונאי, למעט סמני מסחר וסמלילים של צדדים שלשיים בהם נעשה שימוש באתר
          והם בבעלות אותם צדדים. אין להעתיק או לפרסם את דפי האתר במלואם או בחלקם
          ו/או לעשות בהם או במידע שבתוכם שימוש כלשהו, לרבות שימוש מסחרי, ללא
          הסכמתו המפורשת מראש ובכתב של הקמעונאי ו/או של הצדדים השלישיים במידה
          והם בעלי הקניין הרוחני. כמו כן, אין להכניס באתר שינויים, להעתיק,
          להפיץ, לשדר, להציג בפומבי, לשכפל, לפרסם או למסור לצד שלישי כל חלק מן
          האתר בלא קבלת הסכמתו של הקמעונאי מראש ובכתב. פרסומת המוצגת באתר מטעם
          גורם שאינו הקמעונאי הינה קניינו של המפרסם ואין לעשות בה שימוש הפוגע
          בזכות המפרסם.
        </Typography>

        <Typography
          variant="body1"
          style={{ fontSize: "17px", textDecoration: "underline" }}
        >
          1. הגבלת אחריות ושיפוי
        </Typography>

        <Typography
          variant="body1"
          style={{ fontSize: "17px", textDecoration: "underline" }}
        >
          הנך מתחייב ומצהיר כי ידוע לך, ואתה מסכים, כי:
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.1</span> חלק מהמידע בדבר המוצרים
          המוצעים למכירה באתר מקבל הקמעונאי מיצרני המוצרים והוא מזין אותו לאתר
          כפי שהוא. הקמעונאי אינו בודק את מהימנות המידע, שלמותו, דיוקו או התאמתו
          לתכונות המוצרים בפועל, והוא לא יישא באחריות לכל אי התאמה, חוסר, הטעיה
          או טעות הכלולים במידע או בקשר לטיבם או התאמתם לצורכיכם או לתיאורם.
          הקמעונאי אינו אחראי לכל מידע הנוגע למוצרים או למרכיביהם, המופיע
          במתכונים, סרטוני וידאו, טיפים בנושאי תזונה, בריאות וכשרות.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.2</span> השימוש באתר ובשירותים
          המוצעים בו הינו על אחריותך בלבד. השימוש באתר ובתכנים ניתן לך חינם כמות
          שהוא (AS IS) בהתאם להחלטת הקמעונאי, ולא תהיה לך כל טענה, תביעה או
          דרישה כלפי הקמעונאי ו/או לנותני שירות של הקמעונאי בגין תכונות השימוש
          או השירות, יכולותיו ומגבלותיו, התאמתו לצרכיך או התגובות שיעורר (אם
          בכלל) הפרסום, אם וככל שיהיה, באתר.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.3</span> הקמעונאי ו/או נותני
          השירות של הקמעונאי לא יישאו בכל אחריות לכל נזק שיגרם לך במישרין או
          בעקיפין בשל איחור משלוח, או כתוצאה מכך שהמוצרים אשר סופקו לך היו
          פגומים, מקולקלים, או לא תקינים, אלא אם כן ארע הפגם בשל רשלנות מצדו של
          הקמעונאי. במקרה זה, אחריות הקמעונאי תהיה מוגבלת לסכום השווה למחיר
          ההזמנה.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.4</span> הקמעונאי ו/או נותני
          השירות שלו אינם מתחייבים ששירותי האתר יינתנו כסדרם או בלא הפסקות, יהיו
          איכותיים, יתקיימו בבטחה וללא טעויות, ו/או הפרעות ויהיו חסינים מפני
          גישה בלתי-מורשית למחשבי ו/או לתוכנות הקמעונאי או מפני נזקים, קלקולים,
          תקלות או כשלים בין היתר בחומרה, בתוכנה, בקווי ובמערכות תקשורת, אצל
          הקמעונאי או אצל מי מספקיו או אצלך, ולא תהיה לך כל טענה כלפי הקמעונאי
          ו/או לנותני השירות של הקמעונאי בקשר לכך. הנך מודע לכך כי יתכן ובעתיד
          לא יהיה השירות זמין בשל צרכי תחזוקה, שדרוג או מכל סיבה אחרת.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.5</span> ככל שהלקוח חויב בעת
          ביצוע ההזמנה בחיוב יתר או חיוב חסר, הנובע מחיוב שגוי של הקמעונאי,
          יזוכה הלקוח בהפרש בגינו לא היה חייב בתשלום, או לחילופין יחויב בהפרש
          בגינו טרם חויב. ככל שמתבצע עיכוב במועד חיוב הלקוח בסכום התשלום עבור
          ההזמנה, מצהיר הלקוח כי ידוע לו שהחיוב יתבצע במועד אחר.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.6</span> הצבת מידע מסחרי ו/או
          מידע פרסומי באתר נועדו לספק אינפורמציה בלבד ולא יתפרשו כהצעה של האתר
          ו/או של נותני השירות של האתר להשתמש ו/או המלצה, ו/או עידוד, ו/או
          הסכמה, ו/או הבעת תמיכה, ו/או חוות דעת מקצועית ו/או מתן חסות של האתר,
          לתכנים ו/או לשירותים המוצעים באתר אם וככל שיהיו. בעצם פרסום המוצרים
          הקמעונאי ו/או נותני השירות של הקמעונאי אינם מביעים דעתם לגבי התאמת
          המוצרים לצורכיכם ו/או רמתם ואיכותם של המוצרים ו/או של כל מידע,
          פרסומות, קישורים, שירותים, ומוצרים המוצגים באתר. הקמעונאי ו/או נותני
          השירות שלו לא יישאו בכל אחריות לתוכן המודעות אם וככל שיהיה והמידע
          המסחרי שיפורסמו באתר.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.7</span> הקמעונאי אינו מתחייב כי
          כל הקישורים שימצאו באתר יהיו תקינים ויובילו את הגולשים לאתר אינטרנט
          פעיל. הקמעונאי רשאי למחוק מהאתר קישורים שנכללו בו בעבר, או להימנע
          מהוספת קישורים חדשים - הכול לפי שיקול דעתו הבלעדי. העובדה שימצא באתר
          קישור לאתר מסוים אינה מהווה אישור לכך שהמידע באתר זה מלא, אמין, עדכני
          או מהימן.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.8</span> לא תהא לך כל טענה או
          דרישה כלפי הקמעונאי ו/או נותני השירות של הקמעונאי בקשר עם האתר,
          הקמעונאי יהיה רשאי לסגור את האתר ולשנות מעת לעת את מבנהו, מראהו, אופן
          תפעולו וזמינותם של השירותים והמוצרים המוצעים בו וזאת ללא צורך להודיע
          לגולשים מראש על כך. שימוש באתר לאחר שינוי כלשהו באתר מהווה את הסכמתך
          לתנאי האתר, כפי ששונו.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.9</span> האתר מופעל על ידי
          הקמעונאי ובאחריותו. מובהר, כי לבעלי פורטל/אתר שופו ו/או כל חברה מקבוצת
          יוניליוור ישראל לא תהיה כל אחריות על פעילותו של האתר לרבות, עסקאות
          ופעולות מסחריות אחרות המבוצעות בו.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.10</span> ידוע לך כי, בשום מקרה,
          הקמעונאי ו/או נותני השירות שלו ו/או מי מטעמו ו/או קובצת יוניליוור
          ישראל ו/או חברות בת של הקבוצה ו/או סלף פוינט בע"מ ו/או חברות בת שלה,
          אינם נושאים ולא יישאו אחריות כלפיך ו/או כלפי כל צד שלישי, לכל נזק
          (כולל אך לא רק נזק בגין אובדן של בסיס נתונים, אובדן תוכנות, עלויות
          אספקה של שירות חלופי, או הפרעות בשירות) בין אם ישיר, עקיף, משני,
          נלווה, מיוחד או תוצאתי הנובע מהשימוש באתר ו/או בשירותים, חשיפה לאתר,
          גלישה באתר, מאי יכולת להשתמש באתר, מרכישת טובין, רכישת שירותים, ביצוע
          עסקה, משלוח, או כתוצאה משימוש לא מורשה באתר או כתוצאה משינוי המידע
          שנמסר על-ידך, לרבות נזק הנגרם כתוצאה מהפסד ברווחים, שימוש והפסד במידע
          ושאר נכסים בלתי מוחשים וזאת גם אם האתר קיבל הודעה על האפשרות לאותם
          הפסדים.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.11</span> ידוע לך ואתה מתחייב כי
          הנך אחראי לכל תוצאה ישירה או עקיפה הנובעת משימוש באתר ובשירותיו ואתה
          מסכים לשפות את הקמעונאי עובדיו, מנהליו, נותני השירות שלו ו/או מי מטעמו
          ולהגן עליו, בגין כל תביעה, טענה, דרישה הפסד, אבדן-רווח, תשלום, הוצאה
          או נזק, לרבות שכר טרחת עורך דין והוצאות משפט (כולל של צד ג' כלשהו),
          כתוצאה מאופן שימושך ו/או מגלישה ו/או מחוסר יכולת שימוש באתר ו/או מהפרת
          תנאי השימוש באתר ו/או בשירותים. ידוע לך כי השימוש באתר כפוף לכל החוקים
          והתקנות המקומיים, מדינתיים והבינלאומיים הרלבנטיים ואתה מתחייב שלא להפר
          כל חוק ו/או תקנה בקשר עם השימוש באתר ובשירותיו. כמו כן, הנך מתחייב שלא
          לבצע ו/או לנסות לבצע בדיקה ו/או בחינה של רגישות של המערכת, או לפצח
          אמצעי אבטחה או אימות, או להתערב או להפריע למערכות ו/או רשתות המחוברות
          לשירות או להפר את החוקים, תקנות, מדיניות או תהליכים של מערכות ו/או
          רשתות אחרות והנך מתחייב שלא להשיג או לנסות להשיג גישה לא מורשית
          לשירות, לחשבונות אחרים באתר, מערכות מחשבים ורשתות תקשורת המחוברות
          לשירות על-ידי פיצוח סיסמה או כל אמצעי אחר.
        </Typography>

        <Typography
          variant="body1"
          style={{ fontSize: "17px", textDecoration: "underline" }}
        >
          1. התחייבות הגולשים באתר
        </Typography>

        <Typography
          variant="body1"
          style={{ fontSize: "17px", textDecoration: "underline" }}
        >
          בנוסף לכל האמור בתנאי השימוש, אתה מתחייב כי לא תעשה אחד או יותר
          מהדברים הבאים:
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.1</span> תתחזה לאדם או גוף כלשהו,
          לרבות נציג הקמעונאי ו/או תמסור תיאור מסולף או הצגה כוזבת של קשר לאדם
          או גוף כלשהו ו/או תעלה לאתר, תעביר, תשלח או תשדר כל חומר שקרי ומטעה
          ו/או חומר אשר נועד להערים על גולש או על גוף כלשהו. תעשה שימוש שנועד
          להתחקות אחר אדם אחר או תטריד אדם אחר בכל דרך שהיא.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.2</span> תעלה לאתר, תעביר, תשלח
          או תשדר כל חומר המכיל כל סוג של קוד מחשב המתוכנן להרוס, להפריע, או
          להגביל את השימוש (ובכלל זה כל שימוש אחר מלבד שימוש הוגן וסביר בשירות)
          בכל אחד מהמחשבים, השרתים, החומרה והתוכנה אשר מצויים בשימוש הקמעונאי
          לצורך אספקת השירותים המוצעים באתר.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.3</span> תאסוף או תאגור מידע אישי
          על משתמשים אחרים לשם מטרות כלכליות או בלתי חוקיות או תפיץ דואר זבל
          (SPAM) לשרתי האתר ו/או לגולשים באתר. כמו כן לא תמכור, תפיץ או תעשה כל
          שימוש כלכלי במידע באתר או כל שימוש אחר באופן העלול לפגוע באדם אשר
          המידע רלבנטי לו.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.4</span> תציב את האתר, או כל חלק
          ממנו, בתוך מסגרת של אתר אחר או כחלק מאתר אחר ללא הסכמתו מראש ובכתב של
          הקמעונאי.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.5</span> תשנה, תערוך, תעתיק, תשאב
          מידע, תתאים, תיתן רישיון משנה, תתרגם, תמכור, תבצע פעולות של הנדסה
          חוזרת, תפרק או תרכיב מחדש כל אחד מחלקי הקוד המרכיבים את האתר, לרבות
          בסיס המידע, וכן פעולות כאלה לגבי החומרה והתוכנה אשר מצויות בשימוש עבור
          השירות.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.6</span> תשתמש בכל רובוט, מנוע
          אחזור וחיפוש מידע, או בכל כלי אוטומטי או ידני אחר אשר מתוכנן לאנדקס,
          לאתר ולאחזר מידע באתר או כלי כאמור אשר מתוכנן לחשוף את מבנה מאגר
          הנתונים והקוד באתר זה.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.7</span> תפריע או תקטע את פעולת
          האתר (לרבות על ידי הפרעה לפעולות השרת ורשת המחשבים המחוברים לאתר או של
          רשת המחשבים המחוברת אליה).
        </Typography>

        <Typography
          variant="body1"
          style={{ fontSize: "17px", textDecoration: "underline" }}
        >
          1. פרסומות של צדדים שלישיים, קישורים לאתרים אחרים
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          האתר מכיל קישורים לאתרים אחרים אשר אינם קשורים או שייכים לקמעונאי
          (להלן: "האתרים האחרים"). הקמעונאי אינו אחראי לתקינות האתרים האחרים,
          ו/או לשימוש באתרים האחרים ואין ולא תהיה לקמעונאי אחריות, שליטה או
          אפשרות פיקוח על התכנים המצויים באתרים האחרים, ו/או על מדיניות הפרטיות
          של האתרים האחרים ואין בהימצאות הקישורים באתר משום המלצה של הקמעונאי
          לבקר בהם. ביקורך באתרים האחרים והינו לפי שיקול דעתך ועל אחריותך בלבד.
        </Typography>

        <Typography
          variant="body1"
          style={{ fontSize: "17px", textDecoration: "underline" }}
        >
          1. שונות
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.1</span> הקמעונאי אינו מפלה על
          בסיס גיל, מין, גזע, לאום, דת, העדפה מינית או כל סטאטוס אחר.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.2</span> הקמעונאי שומר לעצמו את
          הזכות להשעות, לבטל או לסרב לספק את השירות, וזאת על פי שיקול דעתו
          הבלעדי ומבלי למסור הודעה על כך מראש, לרבות, אך לא רק במקרים הבאים: (1)
          מסירת מידע שאינו אמיתי, מעודכן, שלם או נכון, או אם יש לקמעונאי את
          היסוד הסביר להניח כי כך קרה; (2) כרטיס אשראי של משתמש נחסם או הוגבל
          לשימוש בדרך כלשהי.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.3</span> הקמעונאי רשאי להמחות
          ו/או להעביר ו/או להסב את זכויותיו לפי תנאי שימוש אלה, או חלק מהן, לכל
          תאגיד או גוף צד שלישי לפי שיקול דעתו הבלעדי וללא צורך להודיע על כך
          מראש.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.4</span> על תנאי שימוש אלו יחולו
          דיני מדינת ישראל. מקום השיפוט הבלעדי לכל עניין הנוגע להסכם זה ולשימוש
          באתר הינו בבתי המשפט המוסמכים באזור תל-אביב יפו.
        </Typography>

        <Typography variant="body1" sx={{ fontSize: "17px" }}>
          <span style={{ fontSize: "17px" }}>1.5</span> כל טענה ו/או תביעה כנגד
          הקמעונאי תוגש לא יאוחר משנה אחת מהמקרה שבגינו עלתה הטענה.
        </Typography>
      </BackgroundImageLayout>
    </>
  );
};

export default TermsOfServicePage;
