import React from "react";

type Props = {
  width?: string;
  height?: string;
};

const SvgSodium: React.FC<Props> = ({
  width = "55.133",
  height = "55.133",
}) => {
  return (
    <svg
      data-v-5a2cdfca=""
      role="img"
      aria-label="סימון נתרן בכמות גבוהה"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 83.51 83.51"
    >
      <path
        data-v-5a2cdfca=""
        d="M83.51,41.76A41.76,41.76,0,1,1,41.76,0,41.75,41.75,0,0,1,83.51,41.76"
        fill="#fff"
      ></path>
      <path
        data-v-5a2cdfca=""
        d="M41.75,2.19A39.57,39.57,0,1,0,81.32,41.76,39.56,39.56,0,0,0,41.75,2.19"
        fill="#e42313"
      ></path>
      <path
        data-v-5a2cdfca=""
        d="M41.75,18.89A22.87,22.87,0,1,1,18.89,41.76,22.89,22.89,0,0,1,41.75,18.89"
        fill="#fff"
      ></path>
      <path
        data-v-5a2cdfca=""
        d="M32.37,19.33a1.51,1.51,0,0,1-.87.94,1.46,1.46,0,0,1-1.27.16L24.62,9.69c.07-.34.37-.64.89-.91a1.54,1.54,0,0,1,1.25-.2Z"
        fill="#fff"
      ></path>
      <path
        data-v-5a2cdfca=""
        d="M38.61,14.83a1.42,1.42,0,0,1-1.07.7,1.36,1.36,0,0,1-1.29-.17L35.11,10A3,3,0,0,0,34,8.23a2.57,2.57,0,0,0-2.19-.44A5.12,5.12,0,0,0,29.4,9.2a1,1,0,0,1-.58-.74,1.19,1.19,0,0,1,.19-1A7.46,7.46,0,0,1,32.07,6a4.43,4.43,0,0,1,3.46.45,4.6,4.6,0,0,1,1.93,3Z"
        fill="#fff"
      ></path>
      <path
        data-v-5a2cdfca=""
        d="M49.64,16a1.43,1.43,0,0,1-1.25.28,1.39,1.39,0,0,1-1.14-.6l1.37-6.26a1.88,1.88,0,0,0-.23-1.27,1.25,1.25,0,0,0-1-.59l-2.17-.28c-.25,0-.43.4-.55,1.29l-.37,3.79c-.29,2.2-1.41,3.18-3.35,2.92a2.05,2.05,0,0,1-1.14-.46,1,1,0,0,1-.28-.86,1.07,1.07,0,0,1,.5-.79.82.82,0,0,0,.63.43c.69.09,1.11-.4,1.25-1.48l.29-3.22c.14-1,.46-1.65,1-1.88l-2.07-.27a1,1,0,0,1-.24-.9.9.9,0,0,1,.46-.77l6.51.83q3.57.46,3.12,3.92Z"
        fill="#fff"
      ></path>
      <path
        data-v-5a2cdfca=""
        d="M56.28,18.12c-.26.61-.65.8-1.16.58L51,16.93a.87.87,0,0,1,0-.9c.16-.4.38-.61.64-.62l2.51,1.07c.12.06.23,0,.32-.19l1.85-4.35a1.84,1.84,0,0,0,.1-1.35,1.5,1.5,0,0,0-.87-.89,1.33,1.33,0,0,0-1.06-.06,1,1,0,0,1,0-1c.16-.39.39-.58.68-.6a3,3,0,0,1,1.54.32q3.16,1.35,1.74,4.69Z"
        fill="#fff"
      ></path>
      <path
        data-v-5a2cdfca=""
        d="M50.17,34a2.36,2.36,0,0,0-1.56-2.16,1.67,1.67,0,0,0,.72-1.28c0-3.74-3.39-6.77-7.57-6.77s-7.58,3-7.58,6.77a1.66,1.66,0,0,0,.73,1.28A2.35,2.35,0,0,0,33.34,34l-3,20.53c0,1.3,2.53,2.35,2.53,2.35a30.2,30.2,0,0,0,8.88,1.2,29.43,29.43,0,0,0,8.82-1.2s2.53-1.05,2.53-2.35Z"
        fill="#fff"
      ></path>
      <path
        data-v-5a2cdfca=""
        d="M51,33.94a3.1,3.1,0,0,0-1.12-2.3,2.23,2.23,0,0,0,.28-1.08c0-4.21-3.78-7.64-8.44-7.64s-8.45,3.43-8.45,7.64a2.24,2.24,0,0,0,.29,1.08,3.06,3.06,0,0,0-1.12,2.3l-3,20.46v.13c0,1.79,2.56,2.94,3.12,3.18A31.54,31.54,0,0,0,41.79,59,30,30,0,0,0,51,57.68c.51-.21,3.06-1.36,3.06-3.15Zm-16-3.38c0-3.25,3-5.89,6.71-5.89s6.7,2.64,6.7,5.89c0,.62-.82.88-.82.88a13.74,13.74,0,0,1-5.88,1,13.83,13.83,0,0,1-5.89-1s-.82-.31-.82-.88M50.33,56.05a28.4,28.4,0,0,1-8.54,1.16,29.87,29.87,0,0,1-8.55-1.14c-1-.4-1.92-1.12-2-1.5l3-20.44V34a1.41,1.41,0,0,1,.64-1.15c1.67,1,4.41,1.38,6.9,1.38s5.23-.39,6.89-1.38A1.4,1.4,0,0,1,49.3,34l3,20.57c-.07.38-1,1.1-1.94,1.48M42.86,26.17c0,.41-.46.73-1,.73s-1-.32-1-.73.47-.71,1-.71,1,.32,1,.71m-.06,2.48c0,.41-.46.72-1,.72s-1-.31-1-.72.47-.72,1-.72,1,.32,1,.72m3.33-1.35c0,.4-.46.72-1,.72s-1-.32-1-.72.47-.72,1-.72,1,.32,1,.72m-6.67,0c0,.4-.46.72-1,.72s-1-.32-1-.72.47-.72,1-.72,1,.32,1,.72m2.31,27.5a.87.87,0,0,1-.88-.86V36.85a.87.87,0,0,1,.88-.87.86.86,0,0,1,.86.87V53.94a.86.86,0,0,1-.86.86m6.43-.69a.87.87,0,0,1-.87-.79L45.85,36.67a.87.87,0,0,1,.79-1,.84.84,0,0,1,.94.79l1.49,16.65a.87.87,0,0,1-.79,1H48.2m-12.88,0h-.08a.87.87,0,0,1-.8-1l1.49-16.65a.85.85,0,0,1,1-.79.87.87,0,0,1,.78,1L36.19,53.32a.88.88,0,0,1-.87.79"
        fill="#e42313"
      ></path>
      <path
        data-v-5a2cdfca=""
        d="M8.72,55.27A1.36,1.36,0,0,1,8,54.51a1.23,1.23,0,0,1-.06-1l4.36-2a1.07,1.07,0,0,1,.78.66,1.33,1.33,0,0,1,0,1.12Zm2.07,4.48A1.25,1.25,0,0,1,10,59,1.23,1.23,0,0,1,10,58l4.14-1.92a2.7,2.7,0,0,0,1.47-1.42,2.42,2.42,0,0,0-.13-1.94,4,4,0,0,0-1.71-1.85.89.89,0,0,1,.55-.58,1.06,1.06,0,0,1,.82-.06,5.38,5.38,0,0,1,1.82,2.35,4.09,4.09,0,0,1,.34,3,3.71,3.71,0,0,1-2.17,2.25Z"
        fill="#fff"
      ></path>
      <path
        data-v-5a2cdfca=""
        d="M13.78,63.86a1.39,1.39,0,0,1-.95-.52,1.23,1.23,0,0,1-.34-1l3.63-3.14a1.09,1.09,0,0,1,.94.42,1.32,1.32,0,0,1,.34,1.06ZM17,67.59a1.28,1.28,0,0,1-.92-.5,1.25,1.25,0,0,1-.36-1l3.44-3a2.69,2.69,0,0,0,1-1.78,2.43,2.43,0,0,0-.65-1.83,4,4,0,0,0-2.17-1.3,1,1,0,0,1,.37-.72,1.19,1.19,0,0,1,.78-.28A5.36,5.36,0,0,1,20.91,59a4,4,0,0,1,1.15,2.75,3.71,3.71,0,0,1-1.46,2.76Z"
        fill="#fff"
      ></path>
      <path
        data-v-5a2cdfca=""
        d="M20.54,70.34a1.21,1.21,0,0,1-1-.31,1.15,1.15,0,0,1-.54-.9l5-6.47a1.59,1.59,0,0,1,1.55,1.21Z"
        fill="#fff"
      ></path>
      <path
        data-v-5a2cdfca=""
        d="M29.45,74a.84.84,0,0,1-.09.74,1,1,0,0,1-.56.54l-6.46-3.26a1,1,0,0,1,.1-.76.87.87,0,0,1,.54-.52L27.42,73a2.28,2.28,0,0,1,.33-1.64l.77-1.54a2.64,2.64,0,0,0,.25-1.91,2.26,2.26,0,0,0-1.23-1.46,3.66,3.66,0,0,0-2.09-.35,1.06,1.06,0,0,1,.11-.78,1.17,1.17,0,0,1,.56-.56,4.93,4.93,0,0,1,2.56.66,3.67,3.67,0,0,1,2,2.16,4,4,0,0,1-.37,3.12l-1.07,2.12c-.29.58-.31.93-.07,1Z"
        fill="#fff"
      ></path>
      <path
        data-v-5a2cdfca=""
        d="M36,76.89a1.11,1.11,0,0,1-1,.16,1.24,1.24,0,0,1-.88-.6l.75-3.23-.08,0a1.23,1.23,0,0,0-1.17.36A2.79,2.79,0,0,0,32.81,75l-.25,1.07a1,1,0,0,1-.94.16,1,1,0,0,1-.76-.56l.25-1.07a3.35,3.35,0,0,1,1.34-2.14,3,3,0,0,1,2.39-.35l.22,0,.23-1a2.1,2.1,0,0,0-.07-1.35,1.32,1.32,0,0,0-1-.76,1.54,1.54,0,0,0-1.34.3,1.1,1.1,0,0,1-.12-.84,1,1,0,0,1,.42-.63,3,3,0,0,1,1.84-.06c1.91.44,2.6,1.77,2.09,4Z"
        fill="#fff"
      ></path>
      <path
        data-v-5a2cdfca=""
        d="M51.48,76.1a1.44,1.44,0,0,1-1.94.32L48.65,71a1.75,1.75,0,0,0-.49-1,1,1,0,0,0-.9-.25L45.8,70c-.2,0-.24.43-.11,1.2l.54,3.28q.47,2.85-1.9,3.24a1.61,1.61,0,0,1-1-.11.86.86,0,0,1-.43-.65,1,1,0,0,1,.19-.78.65.65,0,0,0,.6.21c.57-.1.77-.61.62-1.54l-.46-2.77c-.15-.89-.05-1.49.29-1.81l-1.68.28a.9.9,0,0,1-.41-.69.89.89,0,0,1,.17-.76l5-.82q2.9-.48,3.39,2.5Z"
        fill="#fff"
      ></path>
      <path
        data-v-5a2cdfca=""
        d="M55.77,74.33a1.45,1.45,0,0,1-1.82.73l-3.06-7.6a1.19,1.19,0,0,1,.78-.69,1.24,1.24,0,0,1,1,0Z"
        fill="#fff"
      ></path>
      <path
        data-v-5a2cdfca=""
        d="M63.55,66.24a3.33,3.33,0,0,1,.72,2.64A2.94,2.94,0,0,1,63,70.63a2.25,2.25,0,0,1-1.27.51,1.12,1.12,0,0,1-.54-.43A.84.84,0,0,1,61,70a1.49,1.49,0,0,0,.84-.22,1.29,1.29,0,0,0,.56-1A2,2,0,0,0,62,67.46l-1.78-2.55a1.36,1.36,0,0,0-.75-.57.8.8,0,0,0-.68.12c-.4.27-.48.8-.26,1.58l1.72,6a1.29,1.29,0,0,1-.67.93c-.45.32-.8.41-1.06.28L57.18,68a.76.76,0,0,1-.48.21.79.79,0,0,1-.52-.1l-2.5-1.51c0-.26.15-.53.57-.83a1.31,1.31,0,0,1,1.06-.31l2,1.19-.13-.5a2.66,2.66,0,0,1,1-3.16,2.47,2.47,0,0,1,1.74-.5,2.63,2.63,0,0,1,1.85,1.25Z"
        fill="#fff"
      ></path>
      <path
        data-v-5a2cdfca=""
        d="M68.36,58.69a4.39,4.39,0,0,1,1.79,3.17,4.21,4.21,0,0,1-1,3,4.44,4.44,0,0,1-1.79,1.43.91.91,0,0,1-.8-.23.81.81,0,0,1-.38-.71,2.44,2.44,0,0,0,1.47-.85,2.8,2.8,0,0,0,.63-2.06,3.44,3.44,0,0,0-3.58-2.85,2.76,2.76,0,0,0-1.87,1.07,2.37,2.37,0,0,0-.53,1.59.85.85,0,0,1-.76-.22,1,1,0,0,1-.42-.72,4.52,4.52,0,0,1,1-2.05,4.12,4.12,0,0,1,2.7-1.58,4.37,4.37,0,0,1,3.47,1"
        fill="#fff"
      ></path>
      <path
        data-v-5a2cdfca=""
        d="M74.41,52.59a.9.9,0,0,1,.74.06,1,1,0,0,1,.56.55l-3,6.58a1,1,0,0,1-.77-.07.85.85,0,0,1-.53-.52l2.06-4.52a2.27,2.27,0,0,1-1.65-.27l-1.57-.71a2.64,2.64,0,0,0-1.92-.17,2.26,2.26,0,0,0-1.42,1.29,3.68,3.68,0,0,0-.26,2.1,1.12,1.12,0,0,1-.79-.09,1.15,1.15,0,0,1-.57-.54,5,5,0,0,1,.55-2.58,3.68,3.68,0,0,1,2.09-2.05,4,4,0,0,1,3.13.25l2.16,1c.58.27.94.28,1,0Z"
        fill="#fff"
      ></path>
    </svg>
  );
};

export default SvgSodium;
