import React from "react";

type Props = {
  width?: string;
  height?: string;
};

const SvgSupervisedProducts: React.FC<Props> = ({
  width = "55.133",
  height = "55.133",
}) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="b"
        width={width}
        height={height}
        viewBox="0 0 1030 1030"
      >
        <g id="c">
          <g>
            <circle cx="515" cy="515" r="515" fill="#f48619" />
            <circle cx="515" cy="528.97" r="300.12" fill="#fff" />
            <g>
              <path
                d="M173.32,419.89l-101.17-50.92c1.1-4.31,3.31-10.66,6.63-19.03,3.31-8.37,6.63-15.86,9.95-22.46,3.32-6.6,6.48-11.95,9.48-16.05,3-4.1,6.03-7.27,9.1-9.53,3.07-2.26,6.09-3.85,9.07-4.79,2.97-.94,6.22-1.19,9.74-.75,3.52,.44,6.9,1.24,10.15,2.41,3.25,1.17,6.98,2.81,11.18,4.93l67.31,33.88-12.3,24.43-67.39-33.92c-12.3-6.19-21.85-2.52-28.65,11-1.85,3.67-3.87,8.16-6.07,13.46l85.28,42.92-12.3,24.43Z"
                fill="#fff"
              />
              <path
                d="M246.76,300.69l-17.34,21.04-88.98-73.35,17.34-21.04,88.98,73.35Z"
                fill="#fff"
              />
              <path
                d="M312.5,238.59l1.86,2.4-21.42,16.58-1.86-2.4c-2.96-3.82-6.42-9.56-10.39-17.22-3.97-7.66-6.98-14.66-9.02-21l-16.07-49.96-53.37,41.32-12.75-16.47,76.97-59.6,10.07,13.01,16.89,53.51c5.39,17.08,11.75,30.36,19.08,39.83Zm-5.37,63.74l-20,15.49-69.23-89.41,20-15.49,69.23,89.41Z"
                fill="#fff"
              />
              <path
                d="M332.93,185.62l-32.54-69.06,24.66-11.62,22.71,48.2-6.58,28.6-8.25,3.89Z"
                fill="#fff"
              />
              <path
                d="M369.27,126.84c-1.39-5.98-1.66-11.55-.8-16.7,.85-5.15,2.49-9.54,4.93-13.17,2.43-3.62,5.51-6.88,9.25-9.76,6.75-5.18,14.97-8.89,24.67-11.14,4.7-1.09,9.2-1.68,13.5-1.76,4.29-.08,8.89,.67,13.79,2.26,4.89,1.59,9.34,4.09,13.35,7.5,4.01,3.42,7.8,8.42,11.38,15.02,3.58,6.6,6.3,13.91,8.16,21.92,1.86,8.01,3,14.89,3.42,20.64,.42,5.75,.31,10.77-.31,15.08-.62,4.31-1.74,8.24-3.36,11.79-1.62,3.56-3.52,6.57-5.7,9.03-2.18,2.47-4.76,4.66-7.74,6.57-5.27,3.37-11.73,5.94-19.36,7.71-7.63,1.77-14.88,3.24-21.74,4.4-6.86,1.16-11.87,1.9-15.04,2.21l-4.67,.44-4.53-19.5c13.59-2.06,27.27-4.68,41.03-7.88,5.05-1.17,8.86-3.59,11.44-7.25,1.93-2.77,3-6.97,3.23-12.59,.15-3.83-.19-7.79-1.01-11.88-.83-4.09-1.58-7.62-2.27-10.58s-1.29-5.44-1.82-7.42c-.52-1.99-1.26-4.36-2.2-7.11-.95-2.75-1.89-4.99-2.85-6.73-.95-1.74-2.16-3.52-3.63-5.35-1.47-1.83-3.03-3.17-4.69-4-3.97-2.08-8.45-2.53-13.44-1.38-3.66,.85-6.71,2.13-9.16,3.83-2.45,1.7-4.17,3.4-5.15,5.1-.98,1.7-1.66,3.57-2.04,5.61-.44,2.85-.35,5.6,.26,8.24,.61,2.64,1.76,4.99,3.43,7.05,1.67,2.06,3.5,3.53,5.48,4.42,3.76,1.7,7.03,2.5,9.83,2.4l3.45-.16,.17,20.88-4.76,.46c-8.31,.83-14.75,.76-19.32-.19-13.04-2.66-21.42-12-25.14-28.03Z"
                fill="#fff"
              />
              <path
                d="M528.66,63.73c19.34,.99,31.95,5.37,37.82,13.13,3.13,4.16,5.05,8.64,5.75,13.45,.81,5.11,1.05,10.94,.71,17.49l-2.8,54.46,13.21,.68-1.07,20.8-92.13-4.73,1.07-20.8,51.69,2.65,2.8-54.55c.71-13.75-6.5-21.01-21.62-21.79-7.68-.39-15.92-.28-24.72,.34l-4.4,.31,1.1-21.52c8.8-.62,19.66-.6,32.58,.06Z"
                fill="#fff"
              />
              <path
                d="M673.82,90.65c10.64,3.55,19.86,7.14,27.68,10.76,7.81,3.62,13.81,6.86,18.01,9.73,4.19,2.86,7.46,5.79,9.82,8.79,2.35,3,4.05,5.97,5.08,8.91s1.39,6.18,1.07,9.71c-.32,3.53-1.01,6.94-2.08,10.22-1.06,3.28-2.58,7.06-4.56,11.33l-31.69,68.37-24.82-11.5,31.73-68.45c5.79-12.49,1.82-21.92-11.92-28.28-6.92-3.21-15.97-6.71-27.15-10.52l8.83-19.06Z"
                fill="#fff"
              />
              <path
                d="M784.1,297.02l-75.26-63.56,13.44-15.91,34.01,28.72,14.76,14.11,.23-.27,1-40.11,10.28-75.21,23.36,19.73-8.92,60.44c7.11-1.87,14.87-6.94,23.27-15.22l17.9-17.88,20.63,17.42-15.16,15.04c-7.22,7.16-13.16,12.02-17.8,14.57-2.38,1.34-5.16,2.78-8.33,4.32-6.22,2.94-13.5,4.44-21.82,4.5l-1.42,37.29-10.15,12.02Z"
                fill="#fff"
              />
              <path
                d="M809.17,333.15l-16.52-21.69,91.73-69.88,16.52,21.69-91.73,69.88Z"
                fill="#fff"
              />
              <path
                d="M898.78,318.36c9.75-4.32,19.25-10.91,28.5-19.76l10.57,23.87c-1.76,1.76-3.88,3.68-6.35,5.75l-7.77,6.08,.69,1.55c.59,0,1.37,.04,2.36,.13,.98,.09,2.89,.56,5.72,1.43,2.83,.86,5.5,2.04,8.02,3.54,2.52,1.49,5.26,3.85,8.21,7.07,2.96,3.22,5.48,7.19,7.57,11.9,2.09,4.71,3.09,9.4,3.01,14.07-.08,4.66-1.07,8.84-2.97,12.51-1.89,3.67-4.6,7.2-8.12,10.59-6.17,5.93-14.55,11.23-25.12,15.92l-59.66,26.43-19.99-45.12,19.04-8.44,8.69,19.62,37.76-16.73c9.7-4.3,16.14-8.23,19.32-11.79,1.44-1.61,2.67-3.4,3.69-5.35,2.16-3.95,2.03-8.66-.38-14.11-1.11-2.51-2.78-4.68-5-6.54-2.22-1.85-4.53-3.19-6.92-4.02-4.86-1.69-8.9-2.58-12.12-2.65l-2.16-.02-73.04,24.93-10.79-24.36,69.65-23.52c2.6-.89,5.13-1.88,7.58-2.97Z"
                fill="#fff"
              />
            </g>
            <path
              d="M636.36,491.91c-43.87,0-81.41,27.61-96.15,66.37h-29.73c4.25-49.19,15.55-97.57,33.64-143.96,1.81-4.64,3.18-9.42,4.09-14.29,.92-4.87,1.37-9.83,1.37-14.86,0-21.82-8.59-42.23-24.2-57.47-15.61-15.24-36.24-23.36-58.06-22.83-20.75,.49-40.29,8.94-55.02,23.81-14.74,14.86-23.03,34.47-23.35,55.22-.15,10.19,1.57,20.15,5.14,29.61,18.15,48.09,29.52,96.74,33.86,144.77h-77.75c-32.74,0-59.37,26.63-59.37,59.37v56.27c0,.17,.02,.35,.03,.52,0,.11,0,.23,.01,.34,.07,.77,.23,1.52,.47,2.23,.03,.08,.06,.16,.08,.24,.11,.3,.23,.58,.36,.86,.03,.05,.04,.1,.06,.15l16.76,33.75c.31,.63,.7,1.23,1.13,1.78,5.82,7.22,14.47,11.37,23.75,11.37h271.53c9.28,0,17.93-4.15,23.74-11.37,.44-.55,.83-1.15,1.14-1.78l7.19-14.47c56.35-.39,102.08-46.36,102.08-102.82s-46.13-102.82-102.82-102.82Zm-224-85.3c-2.69-7.14-4-14.69-3.88-22.41,.5-32.22,27.1-59.05,59.31-59.81,16.55-.37,32.14,5.75,43.95,17.28,11.82,11.53,18.32,26.99,18.32,43.5,0,7.61-1.39,15.03-4.13,22.05-18.98,48.66-30.75,99.43-35.05,151.06h-43.32c-4.38-50.37-16.2-101.34-35.2-151.67Zm-102,257.55v-46.51c0-21.97,17.87-39.85,39.84-39.85h184.73c-.92,5.51-1.39,11.16-1.39,16.92,0,26.73,10.26,51.12,27.05,69.43h-250.23Zm302.61,38.07c-2.06,2.18-4.91,3.41-7.94,3.41H333.49c-3.04,0-5.88-1.23-7.94-3.41l-9.21-18.53h268.5c9.6,5.58,20.18,9.66,31.43,11.89l-3.3,6.65Zm34.31-25.5c-3.57,.47-7.22,.72-10.92,.72-16.55,0-31.98-4.89-44.92-13.29-22.73-14.76-37.8-40.37-37.8-69.43,0-5.8,.6-11.46,1.74-16.92,1.43-6.86,3.71-13.41,6.74-19.53,13.49-27.39,41.7-46.26,74.24-46.26,45.61,0,82.71,37.1,82.71,82.71,0,41.91-31.32,76.64-71.79,82Z"
              fill="#f48619"
            />
            <path
              d="M696.9,548.65c-3.73-3.73-9.79-3.73-13.53,0l-38.73,38.73-29.2,29.2-4.37-4.37-34.17-34.17c-1.86-1.86-4.31-2.8-6.76-2.8s-4.89,.93-6.76,2.8c-1.81,1.81-2.8,4.21-2.8,6.76s.99,4.96,2.8,6.76l22.66,22.66,9.81,9.8,19.59,19.59,.28-.28h0s81.17-81.17,81.17-81.17c1.81-1.81,2.8-4.2,2.8-6.76s-1-4.95-2.8-6.76Z"
              fill="#f48619"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default SvgSupervisedProducts;
