import React from "react";

type Props = {
  width?: string;
  height?: string;
};

const SvgSugar: React.FC<Props> = ({ width = "55.133", height = "55.133" }) => {
  return (
    <svg
      data-v-5a2cdfca=""
      role="img"
      aria-label="סימון סוכר בכמות גבוהה"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 83.51 83.51"
    >
      <path
        data-v-5a2cdfca=""
        d="M83.51,41.76A41.76,41.76,0,1,1,41.76,0,41.75,41.75,0,0,1,83.51,41.76"
        fill="#fff"
      ></path>
      <path
        data-v-5a2cdfca=""
        d="M41.76,2.19A39.57,39.57,0,1,0,81.32,41.76,39.57,39.57,0,0,0,41.76,2.19"
        fill="#e42313"
      ></path>
      <path
        data-v-5a2cdfca=""
        d="M41.76,18.89A22.87,22.87,0,1,1,18.89,41.76,22.88,22.88,0,0,1,41.76,18.89"
        fill="#fff"
      ></path>
      <path
        data-v-5a2cdfca=""
        d="M34.89,16.17a1.41,1.41,0,0,1-1,.87,1.37,1.37,0,0,1-1.29.05l-2-4.84a3.12,3.12,0,0,0-1.45-1.6A2.6,2.6,0,0,0,27,10.58a4,4,0,0,0-1.87,1.52,1,1,0,0,1-.69-.64,1.13,1.13,0,0,1,0-1A6.12,6.12,0,0,1,27,8.77a4.41,4.41,0,0,1,3.48-.12,4.58,4.58,0,0,1,2.41,2.68Z"
        fill="#fff"
      ></path>
      <path
        data-v-5a2cdfca=""
        d="M43.69,10.79a4.91,4.91,0,0,1-1.44,4,5.3,5.3,0,0,1-3.49,1.49A5.81,5.81,0,0,1,36,15.83a1,1,0,0,1-.44-.87.88.88,0,0,1,.34-.87,3,3,0,0,0,2,.6,3.51,3.51,0,0,0,2.4-1.06A3.37,3.37,0,0,0,41.17,11,3.43,3.43,0,0,0,40,8.37a3.5,3.5,0,0,0-2.5-.77,3,3,0,0,0-1.89.78,1,1,0,0,1-.42-.82,1.06,1.06,0,0,1,.32-.92,5.88,5.88,0,0,1,2.7-.71A5.3,5.3,0,0,1,41.81,7a4.91,4.91,0,0,1,1.88,3.78"
        fill="#fff"
      ></path>
      <path
        data-v-5a2cdfca=""
        d="M46.77,16.16a1.57,1.57,0,0,1-1.26.24,1.5,1.5,0,0,1-1.11-.64l1.53-9.32a1.54,1.54,0,0,1,1.27-.19,1.57,1.57,0,0,1,1.12.59Z"
        fill="#fff"
      ></path>
      <path
        data-v-5a2cdfca=""
        d="M57.06,15.74a5,5,0,0,1-2.72,3,4,4,0,0,1-3.35-.3,4.63,4.63,0,0,1-2.36-2.62,5.78,5.78,0,0,1,.22-4.28,4.93,4.93,0,0,1,2.73-3,4,4,0,0,1,3.33.25,4.83,4.83,0,0,1,2.41,2.63,5.83,5.83,0,0,1-.26,4.27m-2-1A5.32,5.32,0,0,0,55.48,12a2.16,2.16,0,0,0-1.17-1.72,1.73,1.73,0,0,0-1.89.13,4.53,4.53,0,0,0-1.54,2.13,5.2,5.2,0,0,0-.42,2.75A2.13,2.13,0,0,0,51.59,17a1.78,1.78,0,0,0,1.88-.12A4.46,4.46,0,0,0,55,14.75"
        fill="#fff"
      ></path>
      <path
        data-v-5a2cdfca=""
        d="M77.22,27.07a3,3,0,0,0-2.81-1.43,6.13,6.13,0,0,0-2.14.43l-.1,0-.09.06c-.18.12-18,12.32-22.11,14.55a1.1,1.1,0,0,1-.53.16.53.53,0,0,1-.21,0l-.2-.1A30,30,0,0,0,45.34,39c-1.88-3.13-5.2-5.42-8.14-5.42-3.78,0-8,3.61-9.18,7.57a11.8,11.8,0,0,0-1.93,1.47c-1.09,1.05-2.33,2.71-.6,5l.05.06.05,0c3.83,3.59,9.76,4,12.12,4a16.45,16.45,0,0,0,2.76-.2c4.73-.85,7.57-2.72,8.69-5.72.58-1.6,2.28-2.39,4.25-3.3.7-.33,1.44-.67,2.16-1.07,2-1.07,9.22-4.52,14-6.81,1.81-.86,3.34-1.58,4.24-2,4.29-2.07,3.8-4.54,3.39-5.52"
        fill="#fff"
      ></path>
      <path
        data-v-5a2cdfca=""
        d="M78.06,26.72a4,4,0,0,0-3.65-2,5.41,5.41,0,0,0-2.84.68c-.12.08-18,12.3-22,14.51l-.06,0h0A33,33,0,0,0,46,38.32c-2.07-3.27-5.6-5.67-8.83-5.67-4.11,0-8.59,3.65-10,8-.63.41-1.15.94-1.73,1.35-4,2.85-.48,6.41-.48,6.41,4,3.93,10.27,4.22,12.74,4.22a17.16,17.16,0,0,0,2.92-.21c5.08-.91,8.15-3,9.38-6.3.44-1.19,1.73-1.84,3.79-2.79.71-.34,1.46-.69,2.21-1.1,1.93-1.06,9.18-4.51,14-6.78l4.24-2c5.15-2.49,4.24-5.76,3.84-6.7M28.81,41.91c.88-3.77,5-7.33,8.39-7.33S44.48,38,45.6,42a2.37,2.37,0,0,1-.36,2.2c-1.16,1.53-4.31,2.52-8,2.52s-6.74-1-7.93-2.45a2.57,2.57,0,0,1-.46-2.31M73.43,31.77c-3.35,1.62-15.6,7.37-18.3,8.85s-5.87,2.25-6.83,4.86c-1.16,3.11-4.34,4.48-8,5.13a15.64,15.64,0,0,1-2.6.19c-3.25,0-8.27-.71-11.5-3.74-1.05-1.38-.84-2.44.51-3.76l.12-.1a4.34,4.34,0,0,0,.93,2.21c1.57,2,5.1,3.18,9.43,3.18,3.42,0,7.74-.86,9.58-3.28a4.3,4.3,0,0,0,.66-3.88c0-.2-.12-.38-.18-.58.94.46,1.56.83,1.65.86a1.55,1.55,0,0,0,.53.09,2,2,0,0,0,1-.28c4.17-2.28,22.19-14.6,22.19-14.6a5.12,5.12,0,0,1,1.81-.36,2.09,2.09,0,0,1,2,.88C77,29,76,30.55,73.43,31.77"
        fill="#e42313"
      ></path>
      <path
        data-v-5a2cdfca=""
        d="M8.72,55.27A1.36,1.36,0,0,1,8,54.51a1.23,1.23,0,0,1-.06-1l4.36-2a1.07,1.07,0,0,1,.78.66,1.33,1.33,0,0,1,0,1.12Zm2.07,4.48A1.25,1.25,0,0,1,10,59,1.23,1.23,0,0,1,10,58l4.14-1.92a2.7,2.7,0,0,0,1.47-1.42,2.42,2.42,0,0,0-.13-1.94,4,4,0,0,0-1.71-1.85.87.87,0,0,1,.55-.58,1.09,1.09,0,0,1,.83-.06,5.44,5.44,0,0,1,1.81,2.35,4.09,4.09,0,0,1,.34,3,3.71,3.71,0,0,1-2.17,2.25Z"
        fill="#fff"
      ></path>
      <path
        data-v-5a2cdfca=""
        d="M13.78,63.86a1.39,1.39,0,0,1-.95-.52,1.23,1.23,0,0,1-.34-1l3.63-3.14a1.09,1.09,0,0,1,.94.42,1.32,1.32,0,0,1,.34,1.06ZM17,67.59a1.28,1.28,0,0,1-.92-.5,1.25,1.25,0,0,1-.36-1l3.44-3a2.69,2.69,0,0,0,1-1.78,2.43,2.43,0,0,0-.65-1.83,4,4,0,0,0-2.17-1.3,1,1,0,0,1,.37-.72,1.19,1.19,0,0,1,.78-.28A5.44,5.44,0,0,1,20.92,59a4.07,4.07,0,0,1,1.14,2.75,3.71,3.71,0,0,1-1.46,2.76Z"
        fill="#fff"
      ></path>
      <path
        data-v-5a2cdfca=""
        d="M20.54,70.34a1.21,1.21,0,0,1-1-.31,1.15,1.15,0,0,1-.54-.9l5-6.47a1.59,1.59,0,0,1,1.55,1.21Z"
        fill="#fff"
      ></path>
      <path
        data-v-5a2cdfca=""
        d="M29.45,74a.84.84,0,0,1-.09.74,1,1,0,0,1-.56.54l-6.46-3.26a1,1,0,0,1,.1-.76.87.87,0,0,1,.54-.52L27.42,73a2.28,2.28,0,0,1,.33-1.64l.77-1.54a2.64,2.64,0,0,0,.25-1.91,2.26,2.26,0,0,0-1.23-1.46,3.66,3.66,0,0,0-2.09-.35,1.06,1.06,0,0,1,.11-.78,1.17,1.17,0,0,1,.56-.56,4.93,4.93,0,0,1,2.56.66,3.67,3.67,0,0,1,2,2.16,4,4,0,0,1-.37,3.12l-1.07,2.12c-.28.58-.31.93-.07,1Z"
        fill="#fff"
      ></path>
      <path
        data-v-5a2cdfca=""
        d="M36,76.89a1.11,1.11,0,0,1-1,.16,1.24,1.24,0,0,1-.88-.6l.75-3.23-.08,0a1.23,1.23,0,0,0-1.17.36A2.79,2.79,0,0,0,32.81,75l-.25,1.07a1,1,0,0,1-.94.16,1,1,0,0,1-.76-.56l.25-1.07a3.35,3.35,0,0,1,1.34-2.14,3,3,0,0,1,2.39-.35l.22,0,.23-1a2.1,2.1,0,0,0-.07-1.35,1.32,1.32,0,0,0-1-.76,1.54,1.54,0,0,0-1.34.3,1.1,1.1,0,0,1-.12-.84,1,1,0,0,1,.42-.63,3,3,0,0,1,1.84-.06c1.91.44,2.6,1.77,2.09,4Z"
        fill="#fff"
      ></path>
      <path
        data-v-5a2cdfca=""
        d="M51.48,76.1a1.44,1.44,0,0,1-1.94.32L48.65,71a1.75,1.75,0,0,0-.49-1,1,1,0,0,0-.9-.25L45.8,70c-.2,0-.24.43-.11,1.2l.54,3.28q.47,2.85-1.9,3.24a1.61,1.61,0,0,1-1-.11.86.86,0,0,1-.43-.65,1,1,0,0,1,.19-.78.65.65,0,0,0,.6.21c.57-.1.77-.61.62-1.54l-.46-2.77c-.15-.89-.05-1.49.29-1.81l-1.68.28a.9.9,0,0,1-.41-.69.89.89,0,0,1,.17-.76l5-.82q2.9-.48,3.39,2.5Z"
        fill="#fff"
      ></path>
      <path
        data-v-5a2cdfca=""
        d="M55.77,74.33a1.45,1.45,0,0,1-1.82.73l-3.06-7.6a1.19,1.19,0,0,1,.78-.69,1.24,1.24,0,0,1,1,0Z"
        fill="#fff"
      ></path>
      <path
        data-v-5a2cdfca=""
        d="M63.55,66.24a3.33,3.33,0,0,1,.72,2.64A2.94,2.94,0,0,1,63,70.63a2.25,2.25,0,0,1-1.27.51,1.12,1.12,0,0,1-.54-.43A.84.84,0,0,1,61,70a1.49,1.49,0,0,0,.84-.22,1.29,1.29,0,0,0,.56-1A2,2,0,0,0,62,67.46l-1.78-2.55a1.36,1.36,0,0,0-.75-.57.83.83,0,0,0-.69.12c-.39.27-.47.8-.25,1.58l1.72,6a1.29,1.29,0,0,1-.67.93c-.45.32-.8.41-1.06.28L57.18,68a.76.76,0,0,1-.48.21.79.79,0,0,1-.52-.1l-2.5-1.51c0-.26.15-.53.57-.83a1.31,1.31,0,0,1,1.06-.31l2,1.19-.13-.5a2.67,2.67,0,0,1,1-3.16,2.51,2.51,0,0,1,1.75-.5,2.63,2.63,0,0,1,1.85,1.25Z"
        fill="#fff"
      ></path>
      <path
        data-v-5a2cdfca=""
        d="M68.36,58.69a4.39,4.39,0,0,1,1.79,3.17,4.21,4.21,0,0,1-1,3,4.44,4.44,0,0,1-1.79,1.43.91.91,0,0,1-.8-.23.81.81,0,0,1-.38-.71,2.44,2.44,0,0,0,1.47-.85,2.8,2.8,0,0,0,.63-2.06,3.44,3.44,0,0,0-3.58-2.85,2.76,2.76,0,0,0-1.87,1.07,2.37,2.37,0,0,0-.53,1.59.85.85,0,0,1-.76-.22,1,1,0,0,1-.42-.72,4.52,4.52,0,0,1,1-2.05,4.12,4.12,0,0,1,2.7-1.58,4.37,4.37,0,0,1,3.47,1"
        fill="#fff"
      ></path>
      <path
        data-v-5a2cdfca=""
        d="M74.41,52.59a.9.9,0,0,1,.74.06,1,1,0,0,1,.56.55l-3,6.58a1,1,0,0,1-.77-.07.85.85,0,0,1-.53-.52l2.06-4.52a2.27,2.27,0,0,1-1.65-.27l-1.57-.71a2.64,2.64,0,0,0-1.92-.17,2.29,2.29,0,0,0-1.42,1.29,3.68,3.68,0,0,0-.26,2.1,1.12,1.12,0,0,1-.79-.09,1.15,1.15,0,0,1-.57-.54,4.91,4.91,0,0,1,.55-2.58,3.68,3.68,0,0,1,2.09-2.05,4,4,0,0,1,3.13.25l2.16,1c.59.27.93.28,1,0Z"
        fill="#fff"
      ></path>
    </svg>
  );
};

export default SvgSugar;
